import { EyeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ORDER_BUTTON, REFUND_METHOD } from '../../../constants/orderConstants';
import { FALLBACK_IMG } from '../../../constants/styles';
import {
  GoodsInfo,
  OrderData,
  OrderEnum,
  OrderGood,
  TaskData,
} from '../../../types';
import { alertMessage } from '../../../utils/alertMessage';
import { postDataWithAuthToken } from '../../../utils/axiosRequest';
import ValueCardDropdown from '../../activity/common/ValueCardDropdown';
import CouponDropdown from '../../activity/common/CouponDropdown';

type OrderActionModalProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  action: string;
  orderInfo?: OrderData | TaskData['order'];
  orderGoods?: any[];
  claimAmount?: string;
  callBack?: () => void;
  enums?: OrderEnum;
  taskAction?: boolean;
};
const OrderActionModal = ({
  visible,
  setVisible,
  orderGoods,
  orderInfo,
  callBack,
  action,
  claimAmount,
  enums,
  taskAction = false,
}: OrderActionModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [claimPreview, setClaimPreview] = useState<{ [key: string]: string }>();
  const [refundMethod, setRefundMethod] = useState<string>('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<OrderGood[]>([]);
  const [sendNumber, setSendNumber] = useState<{ [key: string]: number }>({});
  const [showSortationReason, setShowSortationReason] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible, form]);

  const onClose = () => {
    setClaimPreview(undefined);
    setRefundMethod('');
    setSelectedRows([]);
    setSelectedRowKeys([]);
    setSendNumber({});
    setShowSortationReason({});
    setVisible(false);
  };

  const calculateReturnAmount = () => {
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        postDataWithAuthToken('order/preview_return', {
          operate: ORDER_BUTTON[action],
          orderId: orderInfo ? orderInfo.orderId : undefined,
          claimGoodsList:
            orderGoods && orderGoods.length
              ? orderGoods.map((good, index) => ({
                  recId: good.recId,
                  claimNumber: values[`${index}|claimNumber`],
                  claimAmount: values[`${index}|claimAmount`],
                }))
              : undefined,
        })
          .then((response) => {
            if (response && response.goodStatus) {
              setIsLoading(false);
              setClaimPreview(response.data);
              form.setFieldsValue({
                totalClaimAmount: response.data.claimAmount,
              });
            } else {
              setIsLoading(false);
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  };

  const onConfirm = () => {
    setIsLoading(true);
    form
      .validateFields()
      .then((values) => {
        let totalClaimReason: string = '';
        if (
          ORDER_BUTTON[action] === ORDER_BUTTON.returnEnabled ||
          ORDER_BUTTON[action] === ORDER_BUTTON.creditMemoEnabled
        ) {
          Object.keys(values).forEach((key) => {
            if (key.includes('claimReason')) {
              totalClaimReason += values[key] + `; `;
            }
          });
        }
        postDataWithAuthToken(
          action === 'remark'
            ? 'order/remark'
            : action === 'batchUpdateSpecialTgEnabled'
            ? 'task/task_goods/update_special'
            : 'order/operate',
          {
            remark:
              action === 'batchUpdateSpecialTgEnabled'
                ? undefined
                : values.remark ||
                  `System: ${t(`order.orderDetail.buttonList.${action}`)}`,
            operate:
              action === 'batchUpdateSpecialTgEnabled'
                ? undefined
                : ORDER_BUTTON[action],
            orderId: orderInfo
              ? orderInfo.orderId
              : orderGoods &&
                orderGoods.length > 0 &&
                (ORDER_BUTTON[action] === ORDER_BUTTON.outOfStockEnabled ||
                  ORDER_BUTTON[action] === ORDER_BUTTON.differenceEnabled)
              ? orderGoods[0].orderId
              : undefined,
            claimAmount:
              (ORDER_BUTTON[action] === ORDER_BUTTON.returnEnabled ||
                ORDER_BUTTON[action] === ORDER_BUTTON.creditMemoEnabled) &&
              values.totalClaimAmount
                ? values.totalClaimAmount
                : values.claimAmount
                ? values.claimAmount
                : undefined,
            refundMethod: values.refundMethod || undefined,
            claimReason:
              action === 'batchUpdateSpecialTgEnabled'
                ? undefined
                : ORDER_BUTTON[action] === ORDER_BUTTON.returnEnabled ||
                  ORDER_BUTTON[action] === ORDER_BUTTON.creditMemoEnabled
                ? totalClaimReason
                : ORDER_BUTTON[action] === ORDER_BUTTON.cancelEnabled &&
                  values.cancelReason
                ? values.cancelReason
                : values.claimReason
                ? values.claimReason
                : orderInfo?.orderShipping?.expectShippingTimeName
                ? `${orderInfo?.orderShipping?.expectShippingTimeName} ${t(
                    'order.title'
                  )}`
                : `System: ${t(`order.orderDetail.buttonList.${action}`)}`,
            claimGoodsList:
              orderGoods && orderGoods.length > 0
                ? ORDER_BUTTON[action] === ORDER_BUTTON.outOfStockEnabled
                  ? orderGoods.map((good, index) => ({
                      recId: good.recId || good.ogId,
                      claimReason: good.content,
                      isSortationMistake:
                        orderInfo && !taskAction
                          ? values[`${index}|isSortationMistake`] ?? false
                          : undefined,
                      sortationReason:
                        orderInfo && !taskAction
                          ? values[`${index}|sortationReason`] ?? ''
                          : undefined,
                    }))
                  : ORDER_BUTTON[action] === ORDER_BUTTON.returnEnabled ||
                    ORDER_BUTTON[action] === ORDER_BUTTON.creditMemoEnabled
                  ? orderGoods.map((good, index) => ({
                      recId: good.recId,
                      claimNumber: values[`${index}|claimNumber`],
                      claimAmount: values[`${index}|claimAmount`],
                      claimReason: values[`${index}|claimReason`],
                      isSortationMistake:
                        values[`${index}|isSortationMistake`] ?? false,
                      sortationReason: values[`${index}|sortationReason`] ?? '',
                    }))
                  : ORDER_BUTTON[action] === ORDER_BUTTON.differenceEnabled
                  ? orderGoods.map((good) => ({
                      recId: good.ogId,
                      actualNumber: good.actualNumber,
                      actualWeight: good.actualWeight,
                      actualPrice: good.actualPrice,
                      claimReason: good.content,
                    }))
                  : undefined
                : undefined,
            cancelReason: values.cancelReason || undefined,
            refundTargetId: values.refundTargetId || undefined,
            orderGoodsList:
              ORDER_BUTTON[action] === ORDER_BUTTON.partialShipEnabled
                ? selectedRows.map((record) => ({
                    recId: record.recId,
                    sendNumber: sendNumber[record.recId],
                  }))
                : undefined,
          }
        )
          .then((response) => {
            if (response && response.goodStatus) {
              setIsLoading(false);
              alertMessage('success', t('order.alerts.orderEdited'));
              // 取消、无效订单，弹出提示
              if (
                ORDER_BUTTON[action] === ORDER_BUTTON.cancelEnabled ||
                ORDER_BUTTON[action] === ORDER_BUTTON.invalidateEnabled
              ) {
                Modal.warning({
                  title: t(
                    'order.orderDetail.buttonList.batchInvalidateTaskEnabled'
                  ),
                  content: `${t('order.orderDetail.alerts.cancelTask', {
                    button: t(
                      'order.orderDetail.buttonList.batchInvalidateTaskEnabled'
                    ),
                  })}`,
                  okText: t('general.ok'),
                  maskClosable: false,
                });
              }
              onClose();
              callBack && callBack();
            } else {
              setIsLoading(false);
              alertMessage(
                'error',
                response?.msg || t('general.noResponse'),
                response?.data || undefined
              );
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
          });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const columns: ColumnsType<OrderGood> = [
    {
      title: t('order.orderDetail.goodsImage'),
      width: 100,
      dataIndex: 'goods',
      render: (good: GoodsInfo) =>
        good && (
          <Image
            src={good.thumbPic}
            fallback={FALLBACK_IMG}
            width={50}
            preview={{ mask: <EyeOutlined /> }}
          />
        ),
    },
    {
      title: t('goods.goodsListColumns.goodsName'),
      width: 200,
      dataIndex: 'goodsName',
    },
    {
      title: t('order.orderDetail.amount'),
      width: 100,
      dataIndex: 'goodsNumber',
    },
    {
      title: t('order.orderDetail.sendNumber'),
      width: 100,
      dataIndex: 'sendNumber',
    },
    {
      title: t('order.orderDetail.addSendNumber'),
      width: 100,
      render: (value, record) =>
        sendNumber[record.recId] !== undefined && (
          <InputNumber
            type="number"
            value={sendNumber[record.recId]}
            max={record.goodsNumber - record.sendNumber}
            min={1}
            precision={0}
            onChange={(value) => {
              setSendNumber((prev) => ({
                ...prev,
                [record.recId]: value || 1,
              }));
            }}
          />
        ),
    },
  ];

  return (
    <Modal
      visible={visible}
      onOk={onConfirm}
      onCancel={onClose}
      title={t(`order.orderDetail.buttonList.${action}`)}
      okText={t('order.orderDetail.ok')}
      cancelText={t('order.orderDetail.cancel')}
      bodyStyle={{ overflow: 'auto', maxHeight: 600 }}
      maskClosable={false}
      okButtonProps={{
        disabled:
          isLoading || ORDER_BUTTON[action] === ORDER_BUTTON.partialShipEnabled
            ? selectedRows.length === 0
            : undefined,
      }}
      width={
        ORDER_BUTTON[action] === ORDER_BUTTON.partialShipEnabled ? 768 : 600
      }
    >
      <Spin spinning={isLoading}>
        <Form form={form} layout="vertical">
          {ORDER_BUTTON[action] === ORDER_BUTTON.partialShipEnabled && (
            <Form.Item label={t('order.orderDetail.orderGoods')}>
              <Table
                size="small"
                dataSource={orderInfo?.orderGoodsList}
                columns={columns}
                scroll={{ y: 330 }}
                rowKey={(record) => record.recId}
                pagination={false}
                rowSelection={{
                  hideSelectAll: true,
                  selectedRowKeys,
                  onSelect: (record, selected) => {
                    setSendNumber((prev) => ({
                      ...prev,
                      [record.recId]: selected ? 1 : undefined,
                    }));
                  },
                  onChange: (
                    selectedRowKeys: React.Key[],
                    selectedRows: OrderGood[]
                  ) => {
                    setSelectedRowKeys(selectedRowKeys);
                    setSelectedRows(selectedRows);
                  },
                  getCheckboxProps: (record: OrderGood) => ({
                    disabled:
                      record.goodsNumber <= 0 ||
                      record.goodsNumber <= record.sendNumber,
                  }),
                }}
              />
            </Form.Item>
          )}
          {ORDER_BUTTON[action] === ORDER_BUTTON.cancelEnabled && (
            <>
              {orderInfo &&
                orderInfo.orderAmount &&
                !!Number(orderInfo.orderAmount.refundableAmount) && (
                  <Form.Item
                    name="refundMethod"
                    label={t('order.orderDetail.refundMethod.title')}
                    rules={[{ required: true }]}
                  >
                    <Select
                      onChange={(value) => {
                        setRefundMethod(value);
                        form.resetFields(['refundTargetId']);
                      }}
                    >
                      {enums &&
                        enums.refundMethod.map((method) => (
                          <Select.Option key={method.code} value={method.code}>
                            {method.description}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}
              {refundMethod === REFUND_METHOD.COUPON && (
                <Form.Item
                  name="refundTargetId"
                  label={t('order.orderDetail.coupon')}
                  rules={[
                    {
                      required: true,
                      message: t('general.inputError.empty'),
                    },
                  ]}
                >
                  <CouponDropdown sellerId={orderInfo?.seller?.sellerId} />
                </Form.Item>
              )}
              {refundMethod === REFUND_METHOD.VALUE_CARD && (
                <Form.Item
                  name="refundTargetId"
                  label={t('order.orderDetail.valueCard')}
                  rules={[
                    {
                      required: true,
                      message: t('general.inputError.empty'),
                    },
                  ]}
                >
                  <ValueCardDropdown
                    userId={orderInfo?.user?.userId}
                    sellerId={orderInfo?.seller?.sellerId}
                  />
                </Form.Item>
              )}
              <Form.Item
                name="cancelReason"
                label={t('order.orderDetail.cancelReason')}
                rules={[{ required: true }]}
              >
                <Input.TextArea autoSize={{ minRows: 2 }} />
              </Form.Item>
            </>
          )}
          {(ORDER_BUTTON[action] === ORDER_BUTTON.returnEnabled ||
            ORDER_BUTTON[action] === ORDER_BUTTON.creditMemoEnabled) &&
            orderGoods &&
            !!orderGoods.length &&
            orderGoods.map((good, index) => (
              <div key={index}>
                <Row gutter={[8, 16]}>
                  <Col span={24}>
                    <Space>
                      <Image
                        src={good.thumbPicPath}
                        fallback={FALLBACK_IMG}
                        width={30}
                        preview={{ mask: <EyeOutlined /> }}
                      />
                      <Typography.Text strong>
                        {good.goods && good.goods.fullGoodsName}
                      </Typography.Text>
                    </Space>
                  </Col>
                  <Col span={24} sm={8}>
                    <Form.Item
                      name={`${index}|claimNumber`}
                      label={t('order.orderDetail.returnNumber')}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      initialValue={good.goodsNumber}
                      style={{ marginBottom: 0 }}
                    >
                      <InputNumber
                        type="number"
                        min={1}
                        max={good.goodsNumber}
                        addonAfter={`/ ${good.goodsNumber}`}
                        style={{ width: '100%' }}
                        onChange={(value) => {
                          // 无数量比例时候，可以根据退货数量来获取退款金额
                          if (
                            !(
                              form.getFieldValue(`${index}|ratioTop`) ||
                              form.getFieldValue(`${index}|ratioBottom`)
                            )
                          ) {
                            setClaimPreview(undefined);
                            form.resetFields(['totalClaimAmount']);
                            if (value && good.goodsNumber) {
                              form.setFieldsValue({
                                [`${index}|claimAmount`]: (
                                  (Number(good.totalPrice) / good.goodsNumber) *
                                  value
                                ).toFixed(2),
                                totalClaimAmount: '',
                              });
                            }
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} sm={8}>
                    <Form.Item
                      label={t('order.orderDetail.returnRatio')}
                      style={{ marginBottom: 0 }}
                    >
                      <Space>
                        <Form.Item
                          name={`${index}|ratioTop`}
                          style={{ marginBottom: 0 }}
                        >
                          <InputNumber
                            type="number"
                            min={0}
                            onChange={(value) => {
                              setClaimPreview(undefined);
                              form.resetFields(['totalClaimAmount']);
                              if (
                                value &&
                                form.getFieldValue(`${index}|ratioBottom`)
                              ) {
                                form.setFieldsValue({
                                  [`${index}|claimAmount`]: (
                                    (Number(good.totalPrice) /
                                      form.getFieldValue(
                                        `${index}|ratioBottom`
                                      )) *
                                    value
                                  ).toFixed(2),
                                  totalClaimAmount: '',
                                });
                              } else {
                                form.resetFields(['${index}|ratioTop']);
                              }
                            }}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }}>/</Form.Item>
                        <Form.Item
                          name={`${index}|ratioBottom`}
                          style={{ marginBottom: 0 }}
                        >
                          <InputNumber
                            type="number"
                            min={0}
                            onChange={(value) => {
                              setClaimPreview(undefined);
                              form.resetFields(['totalClaimAmount']);
                              if (
                                value &&
                                form.getFieldValue(`${index}|ratioTop`)
                              ) {
                                form.setFieldsValue({
                                  [`${index}|claimAmount`]: (
                                    (Number(good.totalPrice) / value) *
                                    form.getFieldValue(`${index}|ratioTop`)
                                  ).toFixed(2),
                                  totalClaimAmount: '',
                                });
                              } else {
                                form.resetFields(['${index}|ratioBottom']);
                              }
                            }}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Space>
                    </Form.Item>
                  </Col>
                  <Col span={24} sm={8}>
                    <Form.Item
                      name={`${index}|claimAmount`}
                      label={t('order.orderDetail.refundAmount')}
                      rules={[{ required: true }]}
                      initialValue={good.totalPrice}
                      style={{ marginBottom: 0 }}
                    >
                      <InputNumber
                        type="number"
                        min={0}
                        max={Number(good.totalPrice)}
                        addonAfter={`/ ${good.totalPrice}`}
                        onChange={() => {
                          setClaimPreview(undefined);
                          form.resetFields(['totalClaimAmount']);
                        }}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={`${index}|claimReason`}
                      label={t('order.orderDetail.claimReason')}
                      rules={[
                        {
                          required: true,
                          message: t('general.inputError.empty'),
                        },
                      ]}
                      style={{ marginBottom: 0 }}
                    >
                      <Input.TextArea autoSize={{ minRows: 2 }} />
                    </Form.Item>
                  </Col>
                  <Col span={24} sm={6}>
                    <Form.Item
                      name={`${index}|isSortationMistake`}
                      label={t('order.orderDetail.isSortationMistake')}
                      style={{ marginBottom: 0 }}
                      valuePropName="checked"
                    >
                      <Switch
                        onChange={(checked) =>
                          setShowSortationReason((prev) => ({
                            ...prev,
                            [`${index}|sortationReason`]: checked,
                          }))
                        }
                      />
                    </Form.Item>
                  </Col>
                  {showSortationReason[`${index}|sortationReason`] && (
                    <Col span={24} sm={18}>
                      <Form.Item
                        name={`${index}|sortationReason`}
                        label={t('order.orderDetail.sortationReason')}
                        rules={[
                          {
                            required: true,
                            message: t('general.inputError.empty'),
                          },
                        ]}
                        style={{ marginBottom: 0 }}
                      >
                        <Input.TextArea autoSize={{ minRows: 1 }} />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                <Divider />
              </div>
            ))}
          {(ORDER_BUTTON[action] === ORDER_BUTTON.outOfStockEnabled ||
            ORDER_BUTTON[action] === ORDER_BUTTON.differenceEnabled) &&
            orderGoods &&
            orderGoods.length > 0 &&
            orderGoods.map((good, index) => (
              <div key={index}>
                <Row gutter={[8, 16]}>
                  <Col span={24}>
                    <Space>
                      <Image
                        src={good.largePicPath}
                        fallback={FALLBACK_IMG}
                        width={30}
                        preview={{ mask: <EyeOutlined /> }}
                      />
                      <Typography.Text strong>
                        {good.fullGoodsName || good.goods.fullGoodsName}
                      </Typography.Text>
                    </Space>
                  </Col>
                  <Col span={24} sm={6}>
                    <Form.Item
                      label={t('order.orderGoodsListColumns.goodsNumber')}
                      style={{ marginBottom: 0 }}
                    >
                      {good.actualNumber && (
                        <Typography.Text>{`${good.actualNumber} / `}</Typography.Text>
                      )}
                      <Typography.Text>{good.goodsNumber}</Typography.Text>
                    </Form.Item>
                  </Col>
                  <Col span={24} sm={6}>
                    <Form.Item
                      label={t('goods.goodsListColumns.goodsWeight')}
                      style={{ marginBottom: 0 }}
                    >
                      {good.actualWeight && (
                        <Typography.Text>{`${good.actualWeight} / `}</Typography.Text>
                      )}
                      <Typography.Text>{good.goodsWeight}</Typography.Text>
                    </Form.Item>
                  </Col>
                  <Col span={24} sm={6}>
                    <Form.Item
                      label={t('order.orderGoodsListColumns.goodsPrice')}
                      style={{ marginBottom: 0 }}
                    >
                      {good.actualPrice && (
                        <Typography.Text>{`${good.actualPrice} / `}</Typography.Text>
                      )}
                      <Typography.Text>{good.goodsPrice}</Typography.Text>
                    </Form.Item>
                  </Col>
                  {orderInfo && !taskAction && (
                    <Col span={24}>
                      <Row>
                        <Col span={6}>
                          <Form.Item
                            name={`${index}|isSortationMistake`}
                            label={t('order.orderDetail.isSortationMistake')}
                            style={{ marginBottom: 0 }}
                            valuePropName="checked"
                          >
                            <Switch
                              onChange={(checked) =>
                                setShowSortationReason((prev) => ({
                                  ...prev,
                                  [`${index}|sortationReason`]: checked,
                                }))
                              }
                            />
                          </Form.Item>
                        </Col>
                        {showSortationReason[`${index}|sortationReason`] && (
                          <Col span={18}>
                            <Form.Item
                              name={`${index}|sortationReason`}
                              label={t('order.orderDetail.sortationReason')}
                              rules={[
                                {
                                  required: true,
                                  message: t('general.inputError.empty'),
                                },
                              ]}
                              style={{ marginBottom: 0 }}
                            >
                              <Input.TextArea autoSize={{ minRows: 1 }} />
                            </Form.Item>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  )}
                  {good.content && (
                    <Col span={24} sm={6}>
                      <Form.Item
                        label={t('sortationSystem.taskDetail.content')}
                        style={{ marginBottom: 0 }}
                      >
                        <Typography.Text>{good.content}</Typography.Text>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                <Divider />
              </div>
            ))}
          {(ORDER_BUTTON[action] === ORDER_BUTTON.refundEnabled ||
            (ORDER_BUTTON[action] === ORDER_BUTTON.outOfStockEnabled &&
              claimAmount !== '0') ||
            (ORDER_BUTTON[action] === ORDER_BUTTON.differenceEnabled &&
              claimAmount !== '0') ||
            ORDER_BUTTON[action] === ORDER_BUTTON.returnEnabled ||
            ORDER_BUTTON[action] === ORDER_BUTTON.creditMemoEnabled) && (
            <>
              {ORDER_BUTTON[action] !== ORDER_BUTTON.creditMemoEnabled && (
                <Form.Item
                  name="refundMethod"
                  label={t('order.orderDetail.refundMethod.title')}
                  rules={[
                    { required: true, message: t('general.inputError.empty') },
                  ]}
                >
                  <Select
                    onChange={(value) => {
                      setRefundMethod(value);
                      setClaimPreview(undefined);
                      form.resetFields(['refundTargetId', 'totalClaimAmount']);
                    }}
                  >
                    {enums &&
                      enums.refundMethod.map((method) => (
                        <Select.Option key={method.code} value={method.code}>
                          {method.description}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
              {refundMethod === REFUND_METHOD.COUPON && (
                <Form.Item
                  name="refundTargetId"
                  label={t('order.orderDetail.coupon')}
                  rules={[
                    {
                      required: true,
                      message: t('general.inputError.empty'),
                    },
                  ]}
                >
                  <CouponDropdown sellerId={orderInfo?.seller?.sellerId} />
                </Form.Item>
              )}
              {refundMethod === REFUND_METHOD.VALUE_CARD && (
                <Form.Item
                  name="refundTargetId"
                  label={t('order.orderDetail.valueCard')}
                  rules={[
                    {
                      required: true,
                      message: t('general.inputError.empty'),
                    },
                  ]}
                >
                  <ValueCardDropdown
                    userId={orderInfo?.user?.userId}
                    sellerId={orderInfo?.seller?.sellerId}
                  />
                </Form.Item>
              )}
              {(ORDER_BUTTON[action] === ORDER_BUTTON.refundEnabled ||
                ORDER_BUTTON[action] === ORDER_BUTTON.outOfStockEnabled ||
                ORDER_BUTTON[action] === ORDER_BUTTON.cancelPayEnabled ||
                ORDER_BUTTON[action] === ORDER_BUTTON.differenceEnabled) && (
                <>
                  <Form.Item
                    name="claimReason"
                    label={t('order.orderDetail.claimReason')}
                    // rules={[
                    //   {
                    //     required:
                    //       ORDER_BUTTON[action] !==
                    //       ORDER_BUTTON.differenceEnabled,
                    //   },
                    // ]}
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 2 }}
                      placeholder={
                        orderInfo?.orderShipping?.expectShippingTimeName
                          ? `${
                              orderInfo?.orderShipping?.expectShippingTimeName
                            } ${t('order.title')}`
                          : `System: ${t(
                              `order.orderDetail.buttonList.${action}`
                            )}`
                      }
                    />
                  </Form.Item>
                  {(ORDER_BUTTON[action] === ORDER_BUTTON.refundEnabled ||
                    ORDER_BUTTON[action] === ORDER_BUTTON.outOfStockEnabled ||
                    ORDER_BUTTON[action] ===
                      ORDER_BUTTON.differenceEnabled) && (
                    <Form.Item
                      name="claimAmount"
                      label={t('order.orderDetail.refundAmount')}
                      rules={[
                        {
                          required:
                            ORDER_BUTTON[action] === ORDER_BUTTON.refundEnabled,
                          message: t('general.inputError.empty'),
                        },
                      ]}
                      initialValue={
                        claimAmount !== undefined ? claimAmount : undefined
                      }
                    >
                      <InputNumber
                        type="number"
                        min={0}
                        max={
                          orderInfo && orderInfo.orderAmount
                            ? Number(orderInfo.orderAmount.refundableAmount)
                            : undefined
                        }
                        readOnly={
                          ORDER_BUTTON[action] !== ORDER_BUTTON.refundEnabled
                        }
                        style={{ width: '100%' }}
                        addonAfter={
                          orderInfo &&
                          orderInfo.orderAmount &&
                          `/ ${orderInfo.orderAmount.refundableAmount}`
                        }
                      />
                    </Form.Item>
                  )}
                </>
              )}
              {(ORDER_BUTTON[action] === ORDER_BUTTON.returnEnabled ||
                ORDER_BUTTON[action] === ORDER_BUTTON.creditMemoEnabled) && (
                <>
                  <Form.Item label={t('order.orderDetail.totalReturnAmount')}>
                    <Row gutter={[8, 8]}>
                      {claimPreview && (
                        <Col span={24}>
                          <Row gutter={[6, 8]}>
                            <Col span={1} style={{ textAlign: 'center' }}>
                              <Typography.Text type="secondary">
                                =
                              </Typography.Text>
                            </Col>
                            <Col>
                              <Space direction="vertical">
                                <Typography.Text type="secondary">
                                  {t('order.orderDetail.refundAmount')}
                                </Typography.Text>
                                <Typography.Text type="secondary">
                                  {claimPreview.claimGoodsAmount}
                                </Typography.Text>
                              </Space>
                            </Col>
                            <Col span={1} style={{ textAlign: 'center' }}>
                              <Typography.Text type="secondary">
                                +
                              </Typography.Text>
                            </Col>
                            <Col>
                              <Space direction="vertical">
                                <Typography.Text type="secondary">
                                  {t('order.claimGoodsList.gstFee')}
                                </Typography.Text>
                                <Typography.Text type="secondary">
                                  {claimPreview.claimGstFee}
                                </Typography.Text>
                              </Space>
                            </Col>
                            <Col span={1} style={{ textAlign: 'center' }}>
                              <Typography.Text type="secondary">
                                +
                              </Typography.Text>
                            </Col>
                            <Col>
                              <Space direction="vertical">
                                <Typography.Text type="secondary">
                                  {t('order.claimGoodsList.pstFee')}
                                </Typography.Text>
                                <Typography.Text type="secondary">
                                  {claimPreview.claimPstFee}
                                </Typography.Text>
                              </Space>
                            </Col>
                            <Col span={1} style={{ textAlign: 'center' }}>
                              <Typography.Text type="secondary">
                                +
                              </Typography.Text>
                            </Col>
                            <Col>
                              <Space direction="vertical">
                                <Typography.Text type="secondary">
                                  {t('order.claimGoodsList.depositFee')}
                                </Typography.Text>
                                <Typography.Text type="secondary">
                                  {claimPreview.claimDepositFee}
                                </Typography.Text>
                              </Space>
                            </Col>
                            <Col span={1} style={{ textAlign: 'center' }}>
                              <Typography.Text type="secondary">
                                +
                              </Typography.Text>
                            </Col>
                            <Col>
                              <Space direction="vertical">
                                <Typography.Text type="secondary">
                                  {t('order.claimGoodsList.recycleFee')}
                                </Typography.Text>
                                <Typography.Text type="secondary">
                                  {claimPreview.claimRecycleFee}
                                </Typography.Text>
                              </Space>
                            </Col>
                            <Col span={1} style={{ textAlign: 'center' }}>
                              <Typography.Text type="secondary">
                                -
                              </Typography.Text>
                            </Col>
                            <Col>
                              <Space direction="vertical">
                                <Typography.Text type="secondary">
                                  {t('order.orderDetail.orderAmount')}
                                </Typography.Text>
                                <Typography.Text type="secondary">
                                  {claimPreview.orderAmount}
                                </Typography.Text>
                              </Space>
                            </Col>
                          </Row>
                        </Col>
                      )}
                      <Col span={24} md={19}>
                        <Form.Item
                          name="totalClaimAmount"
                          style={{ marginBottom: 0 }}
                        >
                          <InputNumber
                            type="number"
                            min={0}
                            max={
                              claimPreview && claimPreview.refundableAmount
                                ? Number(claimPreview.refundableAmount)
                                : 0
                            }
                            readOnly
                            addonAfter={
                              claimPreview && (
                                <Space>
                                  {`/ ${claimPreview.refundableAmount}`}
                                  <Tooltip
                                    title={t(
                                      'order.orderDetail.refundableAmount'
                                    )}
                                  >
                                    <QuestionCircleOutlined />
                                  </Tooltip>
                                </Space>
                              )
                            }
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} md={5}>
                        <Button type="primary" onClick={calculateReturnAmount}>
                          {t('order.orderDetail.calculateAmount')}
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                  {orderInfo &&
                    orderInfo.seller &&
                    orderInfo.seller.isRegionSale &&
                    claimPreview && (
                      <Form.Item label={t('order.orderDetail.mainGoodsFee')}>
                        <Row gutter={[8, 8]}>
                          <Col span={24}>
                            <Row gutter={[6, 8]}>
                              <Col>
                                <Space direction="vertical">
                                  <Typography.Text type="secondary">
                                    {t(
                                      'order.orderDetail.claimMainTotalAmount'
                                    )}
                                  </Typography.Text>
                                  <Typography.Text type="secondary">
                                    {claimPreview.claimMainTotalAmount}
                                  </Typography.Text>
                                </Space>
                              </Col>
                              <Col span={1} style={{ textAlign: 'center' }}>
                                <Typography.Text type="secondary">
                                  =
                                </Typography.Text>
                              </Col>
                              <Col>
                                <Space direction="vertical">
                                  <Typography.Text type="secondary">
                                    {t(
                                      'order.orderDetail.claimMainGoodsAmount'
                                    )}
                                  </Typography.Text>
                                  <Typography.Text type="secondary">
                                    {claimPreview.claimMainGoodsAmount}
                                  </Typography.Text>
                                </Space>
                              </Col>
                              <Col span={1} style={{ textAlign: 'center' }}>
                                <Typography.Text type="secondary">
                                  +
                                </Typography.Text>
                              </Col>
                              <Col>
                                <Space direction="vertical">
                                  <Typography.Text type="secondary">
                                    {t('order.claimGoodsList.gstFee')}
                                  </Typography.Text>
                                  <Typography.Text type="secondary">
                                    {claimPreview.claimMainGstFee}
                                  </Typography.Text>
                                </Space>
                              </Col>
                              <Col span={1} style={{ textAlign: 'center' }}>
                                <Typography.Text type="secondary">
                                  +
                                </Typography.Text>
                              </Col>
                              <Col>
                                <Space direction="vertical">
                                  <Typography.Text type="secondary">
                                    {t('order.claimGoodsList.pstFee')}
                                  </Typography.Text>
                                  <Typography.Text type="secondary">
                                    {claimPreview.claimMainPstFee}
                                  </Typography.Text>
                                </Space>
                              </Col>
                              <Col span={1} style={{ textAlign: 'center' }}>
                                <Typography.Text type="secondary">
                                  +
                                </Typography.Text>
                              </Col>
                              <Col>
                                <Space direction="vertical">
                                  <Typography.Text type="secondary">
                                    {t('order.claimGoodsList.depositFee')}
                                  </Typography.Text>
                                  <Typography.Text type="secondary">
                                    {claimPreview.claimMainDepositFee}
                                  </Typography.Text>
                                </Space>
                              </Col>
                              <Col span={1} style={{ textAlign: 'center' }}>
                                <Typography.Text type="secondary">
                                  +
                                </Typography.Text>
                              </Col>
                              <Col>
                                <Space direction="vertical">
                                  <Typography.Text type="secondary">
                                    {t('order.claimGoodsList.recycleFee')}
                                  </Typography.Text>
                                  <Typography.Text type="secondary">
                                    {claimPreview.claimMainRecycleFee}
                                  </Typography.Text>
                                </Space>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form.Item>
                    )}
                </>
              )}
            </>
          )}
          <Form.Item
            name="remark"
            label={t('order.orderDetail.remark')}
            style={{ marginBottom: 0 }}
          >
            <Input.TextArea
              autoSize={{ minRows: 2 }}
              placeholder={`System: ${t(
                `order.orderDetail.buttonList.${action}`
              )}`}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default OrderActionModal;
